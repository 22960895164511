import { VoidFunctionComponent } from "react";
import PhotoShutter from "./photo.tsx";

const ShutterButton = ({ type }) => {
  if (type === "video") {
    return null;
  }

  return <PhotoShutter />;
};

export default ShutterButton;
