import { useCallback, useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import ShutterButton from "./components/shutter-button/index.tsx";
import useCountDown from "react-countdown-hook";
import fs from "indexeddb-fs";

const Shutter = ({ timeLeft, onCLick }) => {
  const isShooting = !!timeLeft;
  const idleClasses = "bg-grey-window/80";
  const countdownClasses = "bg-red-camera";

  useEffect(() => {
    if (timeLeft === 200) {
    }
  }, [timeLeft]);

  return (
    <div className="backdrop-blur-lg">
      <div
        className={`w-full py-0.5 px-2 space-x-2 h-16 rounded-bl-lg-2 rounded-br-lg-2 flex justify-center ${
          isShooting ? countdownClasses : idleClasses
        }`}
      >
        {isShooting ? (
          <div className="text-5xl flex items-center space-x-12 text-white/30">
            <p className={timeLeft === 3260 ? "text-white" : ""}>3</p>
            <p className={timeLeft === 815 * 3 ? "text-white" : ""}>2</p>
            <p className={timeLeft === 815 * 2 ? "text-white" : ""}>1</p>
            <audio src="/countdown.ogg" autoPlay className="hidden"></audio>
            <div className="pl-4">
              <svg
                width={52}
                id="Capa_1"
                data-name="Capa 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 145.38 145.38"
              >
                <defs>
                  <style>{".cls-2{fill:#ed463f}"}</style>
                </defs>
                <circle cx={72.69} cy={72.69} r={72.69} fill={`rgba(255,255,255, ${timeLeft === 815 ? "1" : ".3"})`} />
                <path
                  className="cls-2"
                  d="M100.84 52.35H44.45a7.9 7.9 0 00-7.88 7.88v33a7.91 7.91 0 007.88 7.88h56.39a7.9 7.9 0 007.88-7.88v-33a7.9 7.9 0 00-7.88-7.88zM72.7 91.94A16.31 16.31 0 1189 75.63a16.31 16.31 0 01-16.3 16.31zm21.64-22.73a3.93 3.93 0 113.94-3.93 3.92 3.92 0 01-3.94 3.93z"
                  transform="translate(-1.01 -1.01)"
                />
                <circle className="cls-2" cx={71.63} cy={74.62} r={11.72} />
                <path
                  className="cls-2"
                  d="M72.64 45.24v7.11H56.28c3.33-2.36 6.64-4.73 10-7.11zM72.64 45.24v7.11H89c-3.33-2.36-6.64-4.73-10-7.11z"
                  transform="translate(-1.01 -1.01)"
                />
              </svg>
            </div>
          </div>
        ) : (
          <button onClick={onCLick}>
            <ShutterButton></ShutterButton>
          </button>
        )}
      </div>
    </div>
  );
};

function App() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [timeLeft, { start }] = useCountDown(3260, 815);
  const [photos, setPhotos] = useState([]);
  const [fsReady, setFsReady] = useState(false);

  const handleOnClick = () => start();

  const draw = useCallback(
    (context, video) => {
      requestAnimationFrame(() => {
        context.drawImage(video, 0, 0);

        if (timeLeft !== 200) {
          draw(context, video);
        }
      });
    },
    [timeLeft]
  );

  const startFs = async () => {
    const exists = await fs.exists("/User/photos");

    if (!exists) {
      await fs.createDirectory("/User");
      await fs.createDirectory("/User/photos");
    }

    setFsReady(true);
  };

  const startDraw = useCallback(
    (context, video) => () => {
      canvasRef.current.width = 720;
      canvasRef.current.height = 480;

      draw(context, video);
    },
    [canvasRef, draw]
  );

  const startStream = useCallback(async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ video: { width: 720, height: 480 } });

    const video = document.createElement("video");

    const context = canvasRef.current.getContext("2d");

    video.srcObject = stream;
    video.autoplay = true;
    video.onloadedmetadata = startDraw(context, video);
  }, [startDraw]);

  const savePhoto = async () => {
    canvasRef.current.toBlob(
      async (blob) => {
        await fs.writeFile(`/User/photos/${Date.now()}.jpg`, blob);
      },
      "jpeg",
      90
    );
  };

  const listFiles = async () => {
    const files = await fs.readDirectory("/User/photos");

    setPhotos(
      await Promise.all(
        files.files.map(async (ref) => {
          const file = await fs.readFile(ref.fullPath);

          const url = URL.createObjectURL(file);

          return url;
        })
      )
    );
  };

  useEffect(() => {
    startFs();
    startStream();
  }, []);

  useEffect(() => {
    if (timeLeft === 815) {
      savePhoto();
    }

    if (!timeLeft && fsReady) {
      listFiles();
    }
  }, [timeLeft, fsReady]);

  return (
    <div className="App flex w-screen h-screen justify-center items-center overflow-hidden">
      <div
        className="w-full h-6 bg-black/10 absolute top-0 left-0 flex items-center"
        style={{ backdropFilter: "blur(256px)" }}
      >
        <div className="mx-5 mb-0.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            height="16px"
            viewBox="0 0 814 1000"
            xmlSpace="preserve"
          >
            <path
              fill="white"
              d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"
            />
          </svg>
        </div>
        <div className="space-x-5 text-white flex items-center text-sm drop-shadow-md">
          <p className="font-bold">Photo Booth</p>
          <p>File</p>
          <p>Edit</p>
          <p>Help</p>
        </div>
      </div>
      <Draggable>
        <div style={{ width: 720 }} className="shadow-sm overflow-hidden rounded-lg-2 border border-black/5">
          <div className="w-full h-7 px-2 flex items-center bg-grey-window rounded-tl-lg-2 rounded-tr-lg-2 relative justify-between border border-bottom-black/5">
            <div className="space-x-2 flex">
              <div className="bg-red border border-red-dark w-3 h-3 rounded-full"></div>
              <div className="bg-yellow border border-yellow-dark w-3 h-3 rounded-full"></div>
              <div className="bg-green border border-green-dark w-3 h-3 rounded-full"></div>
            </div>
            <h1 className="font-bold text-title-text text-sm mr-1">Photo Booth</h1>
            <div className="w-12 ml-1"></div>
          </div>
          <div className="relative">
            {!timeLeft && (
              <div
                className="h-8 absolute bottom-0 left-0 w-full overflow-y-hidden overflow-x-scroll custom-scrollbar bg-grey-window/80"
                style={{ height: 62 }}
              >
                <div className="py-0.5 flex space-x-1 px-2">
                  <div className="h-1 w-1"></div>
                  {photos.map((blobUrl) => {
                    return <img className="last:pr-2" src={blobUrl} alt="" style={{ height: 58 }} />;
                  })}
                </div>
              </div>
            )}
            <canvas ref={canvasRef} style={{ width: 720, height: 480, backgroundColor: "black" }}></canvas>
          </div>
          <Shutter timeLeft={timeLeft} onCLick={handleOnClick} />
        </div>
      </Draggable>
    </div>
  );
}

export default App;
