const PhotoShutter = () => (
  <svg width={52} id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 145.38 145.38">
    <defs>
      <style>{".cls-2{fill:#fff}"}</style>
    </defs>
    <circle cx={72.69} cy={72.69} r={72.69} fill="#ff3a2f" />
    <path
      className="cls-2"
      d="M100.84 52.35H44.45a7.9 7.9 0 00-7.88 7.88v33a7.91 7.91 0 007.88 7.88h56.39a7.9 7.9 0 007.88-7.88v-33a7.9 7.9 0 00-7.88-7.88zM72.7 91.94A16.31 16.31 0 1189 75.63a16.31 16.31 0 01-16.3 16.31zm21.64-22.73a3.93 3.93 0 113.94-3.93 3.92 3.92 0 01-3.94 3.93z"
      transform="translate(-1.01 -1.01)"
    />
    <circle className="cls-2" cx={71.63} cy={74.62} r={11.72} />
    <path
      className="cls-2"
      d="M72.64 45.24v7.11H56.28c3.33-2.36 6.64-4.73 10-7.11zM72.64 45.24v7.11H89c-3.33-2.36-6.64-4.73-10-7.11z"
      transform="translate(-1.01 -1.01)"
    />
  </svg>
);

export default PhotoShutter;
